@import '../styles/mixins/index.scss';


.home-page-alignment {
  padding: 60px 0;
  display: flex;
  align-items: center;

  @include breakpoint("sm-max") {
    min-height: auto;
    padding: 60px 0;
  }

  .w-fill {
    width: 100%;
  }

  div {
    transition: 0.5s ease-in-out;
  }
  .keywords-relative {
    position: relative;
    .plus-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      right: 15px;
      color: #fff;
      i {
        cursor: pointer;
      }
    }
  }
  .grid {
    display: flex;
    gap: 60px;
    align-items: center;
    transition: 0.5s ease-in-out;

    @include breakpoint("md-max") {
      flex-wrap: wrap;
    }

    @include breakpoint("sm-max") {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    &.grid-change {
      gap: 60px;
      transition: 0.5s ease-in-out;

      flex-wrap: wrap;

      .grid-items:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr 267px;
        gap: 20px;
        transition: 0.5s ease-in-out;
        align-items: flex-start;
        width: 100%;

        @include breakpoint("md-max") {
          grid-template-columns: 1fr;
          gap: 0;
        }

        .button-design {}

        .two-btn-alignment {
          margin: 30px 0 0 0;

          @include breakpoint("md-max") {
            margin: 0;
          }
        }
      }

      .grid-items:last-child {
        width: 100%;
      }
    }

    .grid-items {
      width: 50%;
      transition: 0.5s ease-in-out;

      @include breakpoint("md-max") {
        width: 100%;
      }
    }

    .card {
      height: calc(100vh - 280px);
      overflow: auto;
      background-image: linear-gradient(180deg, #1c1f26, #1c1f26), linear-gradient(180deg, #9697fc, #4a4efa00);
      border-radius: 16px;
      border: 1px solid transparent;
      background-origin: padding-box, border-box;
      background-position: 0% center, 0% center;
      background-blend-mode: normal, normal;
      background-clip: padding-box, border-box;
      background-attachment: scroll, scroll;
      background-repeat: repeat, repeat;
      padding: 32px;

      @include breakpoint("sm-max") {
        padding: 20px;
      }

      .copy-icon {
        position: absolute;
        top: 15px;
        right: 15px;

        i {
          cursor: pointer;
          font-size: 20px;
          color: #fff;
        }
      }

      .h-full-alignment {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-center {
          padding: 0 0 40px 0;
          display: flex;
          justify-content: center;

          img {
            @include breakpoint("sm-max") {
              width: 100%;
            }
          }
        }

        h4 {
          color: #a8b3cf;
          font-size: 24px;
          font-weight: 600;
          line-height: 38px;
          text-align: center;
        }
      }

      h2 {
        font-size: 22px;
        color: #fff;
        font-weight: 600;
        line-height: 32px;
        margin: 0 0 20px 0;
      }

      p {
        color: #7b8082;
        font-size: 16px;
        margin: 0 0 20px 0;
        line-height: 28px;
        font-weight: 500;
      }
    }

    .keyword-link {
      padding: 0 0 30px 0;
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      transition: 0.5s ease-in-out;

      button {
        background-color: transparent;
        border: 1px solid #242424;
        background-color: transparent;
        padding: 8px 16px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 9px;
        font-weight: 500;
        border-radius: 99px;
      }
    }

    .grid-items {
      position: relative;

      .loader-design-center {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-color: rgb(0, 0, 0, .9);
        p {
          color: #fff;
          text-align: center;
          margin: 0 0 15px 0;
          letter-spacing: 1px;
        }
        .progress {
          width: 174.4px;
          height: 24.6px;
          border-radius: 22.4px;
          color: #ffffffd4;
          border: 2.2px solid;
          position: relative;
        }

        .progress::before {
          content: "";
          position: absolute;
          margin: 2.2px;
          inset: 0 100% 0 0;
          border-radius: inherit;
          background: currentColor;
          animation: progress-pf82op 2s infinite;
        }

        @keyframes progress-pf82op {
          100% {
            inset: 0;
          }
        }
      }
    }

    .grid-items {
      .reset {
        width: 60px;
        height: 60px;
        background-color: #272b34;
        border-radius: 12px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 22px;
        cursor: pointer;
        justify-content: center;
      }

      .two-btn-alignment {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .button-design {
        border: none;
        background-color: #272b34;
        padding: 12px 24px;
        border-radius: 12px;
        height: 60px;
        white-space: nowrap;
        font-weight: 500;
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        &:hover {
          opacity: 0.8;
        }
      }

      .input {
        padding: 0 0 30px 0;

        .input-label {
          display: flex;
          gap: 10px;
        }

        label {
          // color: #a8b3cf;
          color: #fff;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          display: block;
          padding: 0 0 12px 0;
        }

        textarea {
          padding: 16px;
          background-color: #1c1f26;
          width: -webkit-fill-available;
          border: 1px solid #242424;
          resize: none;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #fff;
          height: 210px;

          &:focus {
            outline: none;
            border: 1px solid #fff;
          }
        }

        input {
          padding: 0 16px;
          background-color: #1a1f26;
          width: -webkit-fill-available;
          border: 1px solid #242424;
          resize: none;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #fff;
          height: 60px;

          &:focus {
            outline: none;
            border: 1px solid #fff;
          }
        }

        ::placeholder {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #a8b3cf;
        }
      }

      .markdown-content {
        padding-left: 1em;

        @include breakpoint("sm-max") {
          padding-left: 0;
        }

        h1 {
          margin: 0 0 20px 0;
          font-size: 26px;
          font-weight: 500;
          line-height: normal;
          line-height: 1.5;
          color: #fff;

          @include breakpoint("sm-max") {
            font-size: 24px;
            margin: 0 0 15px 0;
            line-height: normal;
          }
        }

        h2 {
          margin: 0 0 15px 10px;
          font-size: 22px;
          font-weight: 500;
          line-height: 1.4;
          color: #fff;

          @include breakpoint("sm-max") {
            font-size: 20px;
            margin: 0 0 15px 0;
            line-height: normal;
          }
        }

        h3 {
          font-size: 20px;
          line-height: 1.5;
          color: #fff;
          font-weight: 500;
          margin: 0 0 15px 15px;

          @include breakpoint("sm-max") {
            font-size: 18px;
            margin: 0 0 15px 0;
            line-height: normal;
          }
        }

        h4 {
          font-weight: 500;
          line-height: 1.5;
          font-size: 19px;
          margin: 0 0 15px 20px;
          color: #fff;

          @include breakpoint("sm-max") {
            font-size: 24px;
            margin: 0 0 15px 0;
            line-height: normal;
          }
        }

        p {
          color: #a7a7a7de !important;
          line-height: 1.6;
          font-size: 17px;
          font-weight: 500;
          margin: 0 0 15px 25px;

          @include breakpoint("sm-max") {
            font-size: 14px;
            line-height: 26px;
            margin: 0 0 15px 0;
          }
        }

        ol {
          color: #fff;
          margin-left: 2em;

          @include breakpoint("sm-max") {
            margin: 0;
            padding: 0 0 0 15px;
          }
        }

        ul {
          color: #fff;
          margin-left: 2em;

          @include breakpoint("sm-max") {
            margin: 0;
            padding: 0 0 0 15px;
          }
        }

        code {
          color: #fff;
          font-weight: 500;
          line-height: 1.5;
          font-size: 16px;
          margin: 0 0 10px 15px;
          max-width: 300px;
        }

        li {
          color: #fff;
          margin: 0 0 7px 16px;
          font-size: 15px;
          line-height: 26px;
          padding: 0 0 10px 0;
        }

        table {
          color: #fff;
          margin-left: 1em;
          border-collapse: collapse;
        }

        th {
          color: #fff;
          padding: 8px;
          border: 1px solid #ccc;
        }

        td {
          color: #fff;
          padding: 8px;
          border: 1px solid #ccc;
        }

        blockquote {
          color: #fff;
          margin-left: 1.5em;
          padding-left: 1em;
          border-left: 3px solid #ccc;
          color: #666;
          font-style: italic;
        }
      }
    }
  }
}