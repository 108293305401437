body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background-color: #0e1217;
}

textarea {
  font-family: "Inter", sans-serif;

}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

button {
  font-family: "Inter", sans-serif;
}

input {
  font-family: "Inter", sans-serif;
}

::placeholder {
  font-family: "Inter", sans-serif;
}



.container {
  max-width: calc(1280px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 999px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 999px;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9aeae; 
  border-radius: 999px;

}

button {
  cursor: pointer;
}